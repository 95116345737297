import React, { useContext } from "react"
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import app from "gatsby-plugin-firebase-v9.0"
import { getAuth, GoogleAuthProvider } from "firebase/auth"
import { setUser, isLoggedIn } from "../utils"
import { navigate } from "gatsby"

import Layout from "../layouts/main"
import Seo from "../components/seo"
import StoreContext from "../context/storeContext"

export default function Login() {
  const { setDocument } = useContext(StoreContext)

  if (isLoggedIn()) {
    navigate(`/app/dashboard`)
  }

  const uiConfig = {
    signInFlow: "popup",
    signInSuccessUrl: "/app/dashboard",
    signInOptions: [GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      signInSuccessWithAuthResult: async result => {
        try {
          const { uid, displayName, email } = result.user
          setDocument("users", uid, { name: displayName, email })
          setUser({ ...result.user })
          navigate(`/app/dashboard`)
        } catch (error) {
          console.log(error)
        }
      },
    },
  }

  return (
    <Layout>
      <Seo title="Seychelles Aluminium Windows and Doors Specialist" description="Seychelles aluminium windows and doors specialist since 2001. We have a wide selection of products: Casement, Sliding and folding frames with Clear, Bronze, Tinted, Mirror and Laminated Safely Glass" />
      {!isLoggedIn() && (
        <div className="mt-20">
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={getAuth(app)} />
        </div>
      )}
    </Layout>
  )
}
